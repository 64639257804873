import React, { useEffect, useState } from "react";

import posed from 'react-pose';

const FadeWrapper = posed.div({
	visible: {
		opacity: 1,
		transition: { duration: 2000 }
	},
	hidden: {
		opacity: 0
	}
});

const Fade = ( { className, style, children, delay = 300 } ) => {
	const [ isVisible, updateVisible ] = useState( false );
	useEffect( () => {
		setTimeout( () => updateVisible( true ), 300 );
	});

	return <FadeWrapper className = { className } style = { style } pose={isVisible ? 'visible' : 'hidden' }>{children}</FadeWrapper>
};

export default Fade;

