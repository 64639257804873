import { useStaticQuery, graphql } from "gatsby"

const useSiteMetaData = () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        email
                    }
                }
            }
    `
    )

    return data.site.siteMetadata;
}

export default useSiteMetaData;