import React from "react";
import { Link } from "gatsby";
import useSiteMetaData from '../hooks/siteMetaData';
import style from './footer.module.scss';

const DarkFooter = () => {
	const metaData = useSiteMetaData();

	return <div className='bt tc flex flex-column items-center pa3 w-100 bg-black near-white'>
		<ul className='ma0 pa0 flex items-end mt3' style={{ flex: 1 }}>
			<li className = { style.link }><Link className="link near-white" to="/">Home</Link></li>
			<li className = { style.link }><Link className="link near-white" to="/about">About</Link></li>
			<li className = { style.link }><Link className="link near-white" to="/contact">Contact</Link></li>
		</ul>
		<p className='w-90 tc pa0 ma0 mv4' >
			This site is copyright. Feel free to email us at <a className = 'near-white link' href={`mailto:${metaData.email}`}>{metaData.email}</a>
		</p>
	</div>;
}

export default ( { bio } ) => {
	const metaData = useSiteMetaData();

	return <div className='bt tc flex pa3 w-100'>
		<ul className = 'ma0 pa0 flex flex-column items-end' style = {{ flex: 1 }}>
			<li><Link className="link link-dark" to="/">Home</Link></li>
			<li><Link className="link link-dark" to="/about">About</Link></li>
			<li><Link className="link link-dark" to="/contact">Contact</Link></li>
		</ul>
		<div className = 'h3 mh4 self-center' style = {{ borderLeft: '1px solid black'}}></div>
		<div style = {{ flex: 1 }} >
			<p className = 'w-50 tl pa0 ma0' >
				This site is copyright. Feel free to email us at <a href={`mailto:${metaData.email}`}>{metaData.email}</a>
			</p>
		</div>
	</div>;
}
export {
	DarkFooter
}