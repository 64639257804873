import React from "react"
import { Link } from "gatsby"
import styles from './menu.module.scss';

const links = [
    { name: 'home', href: '/' },
    { name: 'about', href: '/about' },
    { name: 'contact', href: '/contact' },
];

export default ({ show }) => {
    return (
       <div className = { styles.menu } style = {{display: show ? 'block' : 'none' }}>
            <ul className="flex-ns justify-end" style={{ flex: 1 }}>
                {links.map((link, i) => <li key={i} className="ml3 mb3 f2"><Link className="link light-silver" to={link.href}>{link.name}</Link></li>)}
            </ul>
       </div>
    )
}