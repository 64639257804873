import React, { useState } from "react"
import { Link } from "gatsby"
import { FaBars, FaTimes } from 'react-icons/fa'

import Menu from './menu';

import Fade from './fade.js'

const links = [
	{ name: 'home', href: '/' },
	{ name: 'about', href: '/about' },
	{ name: 'contact', href: '/contact' },
];


export default () => {
	const [ showMenu, setShowMenu ] = useState( false );
	return (
		<div className='bg-black light-silver w100 h3'>
		<Fade className='w100 h100 flex items-center w100 h3 ph3'>
			<h1><Link className="link light-silver" to='/'>humility is...</Link></h1>
			<ul className="dn flex-ns justify-end" style={{flex: 1}}>
				{links.map( (link, i) => <li key = { i } className="ml3"><Link className="link light-silver" to={link.href}>{link.name}</Link></li>)}
			</ul>
			<span style = {{flex: 1}} className = "dn-ns"></span>
			<span className = "dn-ns" onClick = { () => setShowMenu( !showMenu ) }>
					{showMenu ? <FaTimes /> : <FaBars /> }
			</span>
		</Fade>
		<Menu show = { showMenu }/>
		</div>
	)
}